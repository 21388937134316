<template>
  <v-container class="pa-0" fluid>
    <BannerCompras />
    <ComprasSubBanner />
    <ComprasControl />
    <ComprasVentajas />
    <AllYouNeed />
    <CTAInv />
  </v-container>
</template>

<script>
  import BannerCompras from '../components/sections/BannerCompras.vue'
  import ComprasControl from '../components/sections/ComprasControl.vue'
  import ComprasSubBanner from '../components/sections/ComprasSubBanner.vue'
  import ComprasVentajas from '../components/sections/ComprasVentajas.vue'
  import AllYouNeed from '../components/sections/AllYouNeed.vue'
  import CTAInv from '../components/sections/CTAInv.vue'

  export default {
    name: 'Compras',

    components: {
      BannerCompras,
      ComprasControl,
      ComprasSubBanner,
      ComprasVentajas,
      AllYouNeed,
      CTAInv
    },
  }
</script>