<template>
  <v-container class="bg-section mb-16" fluid>
    <v-row class="justify-center align-center mb-14">
      <v-col cols="10" :md="6" class="mt-4">
        <div class="ml-lg-16 mb-14">
          <h6 class="ml-lg-16 pl-lg-16">MANTENTE INFORMADO</h6>
          <h2 class="b-6 ml-lg-16 pl-lg-16">
            Recibe notificaciones al segundo
          </h2>
          <p class="ml-lg-16 pl-lg-16">
            Entrar todos los días en tu banca online para comprobar los movimientos
            realizados, requiere tiempo. Acist te ahorra este paso y te
            mantiene informado.
          </p>
        </div>
      </v-col>
      <v-col cols="10" :md="6" class="cont-img">
        <v-img
          :src="require('@/assets/compras/compras-sec.png')"
          contain
          height="280"
          class="mr-lg-16"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ComprasControl",

  data: () => ({}),
};
</script>

<style scoped>
.cont-img {
  text-align-last: right;
}
</style>
