<template>
  <v-container class="bg-section mb-16" fluid>
    <v-row class="justify-center align-center mb-14">
      <v-col cols="3" xs="0" sm="0" md="0" lg="3" class="hidden-md-and-down">
        <v-img
          :src="require('@/assets/compras/woman-consultant.png')"
          contain
          class="mt-8 back"
          height="400"
        />
      </v-col>
      <v-col cols="1"  class="hidden-md-and-down"></v-col>
      <v-col cols="10" xs="10" sm="10" md="7" lg="5" class="">
        <div class="mb-14">
          <h6>GESTIONA TUS GASTOS DE MANERA SENCILLA</h6>
          <h2 class="mb-6">Ventajas de hacer tu control con Acist</h2>
          <p class="">
            Nuestro software está diseñado para gestionar tu negocio fácilmente desde cualquier dispositivo. 
          </p>
          <v-row class="align-right mt-6">
            
            <v-col cols="12" xs="12" sm="12" md="6">
              <v-row>
                <v-col cols="4">
                  <v-img
                    :src="require('@/assets/icons/im.png')"
                    contain
                    height="80"
                  />
                </v-col>
                <v-col cols="8">
                  <h5>Herramienta flexible</h5>
                  <p style="font-size: 12px">
                    Es una herramienta flexible y accesible desde cualquier dispositivo.
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-img
                    :src="require('@/assets/icons/icloud.png')"
                    contain
                    height="80"
                  />
                </v-col>
                <v-col cols="8">
                  <h5>Almacenamiento</h5>
                  <p style="font-size: 12px">
                    Almacena en la nube automáticamente todo el historial de gastos.
                  </p>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6">
              <v-row>
                <v-col cols="4">
                  <v-img
                    :src="require('@/assets/icons/it.png')"
                    contain
                    height="80"
                  />
                </v-col>
                <v-col cols="8">
                  <h5>Ahorro de tiempo</h5>
                  <p style="font-size: 12px">
                    Podrás consultar todos los gastos enviados y revisarlos cuando los necesites.
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-img
                    :src="require('@/assets/icons/imoney.png')"
                    contain
                    height="80"
                  />
                </v-col>
                <v-col cols="8">
                  <h5>Presupuestos</h5>
                  <p style="font-size: 12px">
                    Con Acist también puedes crear presupuestos y hacerles seguimiento.
                  </p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <!-- End Block -->
    <hr class="mt-20 mb-20" />
  </v-container>
</template>

<script>
export default {
  name: "ComprasVentajas",

  data: () => ({}),
};
</script>

<style scoped>
.play {
  position: absolute;
  z-index: 1;
  margin-top: 200px;
  margin-left: 5%;
}
</style>
