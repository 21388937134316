<template>
  <v-container class="bg-section mb-16" fluid>
    <div class="bg-cards">
      <v-row class="justify-center align-center text-center pa-8">
        
        <v-col cols="12" :md="2" class="mx-2 cont-msg">
          <h6>Ve cuál es la compra exacta que acabas de realizar.</h6>
        </v-col>
        <v-col cols="12" :md="2" class="mx-2 cont-msg">
          <h6>Ten constancia de todo en tus cuentas bancarias.</h6>
        </v-col>
        <v-col cols="12" :md="2" class="mx-2 cont-msg">
          <h6>Lleva un control exhaustivo de todas tus compras.</h6>
        </v-col>
        
        <v-col cols="12" :md="4">
          <div class="mr-2 ml-12 text-center">
            <h3 class="title-main">Automatiza la gestión de tus compras</h3>
          </div>
        </v-col>
        <v-col :md="1">
          <v-img
            :src="require(`@/assets/icons/control-s.png`)"
            contain
            height="80"
            class="my-6"
          />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "ComprasSubBanner",

  data: () => ({}),
};
</script>

<style scoped>
.bg-cards {
  background: #f5f5f5;
}

.bg-card {
  background: #ffffff;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.12);
  border-radius: 14px;
}

.title-main {
  font-size: 20px;
}

.cont-msg {
    border-right: solid rgba(196, 196, 196, 0.4);
}
</style>
