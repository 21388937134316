<template>
  <v-container fluid>
      <v-row class="mt-4 mt-md-16">
          <v-col cols="12" :md="6">
              <div>
                  <v-img
                    :src="require('@/assets/compras/BannerCompras.png')"
                    class=""
                    contain
                    height="460"
                    />
              </div>
          </v-col>
          <v-col cols="12" :md="5" class="mr-md-14">
              <div class="mb-8 mx-xs-8 mx-sm-10 ml-lg-16 mt-4 mt-md-16 text-center justify-center align-center">
                  <h1 class="mb-6">Tus compras siempre organizadas</h1>
                  <p class="pb-8 mt-8">Con Acist podrás mantener una correcta gestión de tus compras, y hacer un seguimiento de su estado.</p>
              </div>
              <v-row class="justify-center align-center mb-xs-10 mb-sm-8">
                <div class="text-center mx-lg-12">
                <v-btn
                    rounded
                    x-large
                    class="mainButton"
                    dark
                >
                    Probar Gratis
                </v-btn>
                </div>
                <div class="text-center ml-10">
                <v-btn
                    rounded
                    x-large
                    color="primaryLint"
                    class="red--text"
                    dark
                >
                    Contactarme 
                </v-btn>
                </div>
            </v-row>
          </v-col>
          
      </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'BannerFacturacion',

    components: {
    },
    data: () => ({
    }),
  }
</script>

<style scoped>
.bg-card {
  background: #141E56;
    border-radius: 20px;
}
.icon-banner {
    background-position: left;
}

@media (max-width: 959px) {
  .inline-with-text {
    display: flex;
}
}
</style>
